<template>
	<div class="main">
		<div class="secondary-banner">
			<img :src="img_list[0].img" alt="" style="width: 100%;">
		</div>
		<div class="contactus container-secondary-main">
			<h5>{{lang.contact_us}}</h5>
			<div class="us-info">
				<div class="us-info-li us-other">
					<p><img src="@/assets/contactus/contactus_icon_1.png"/>{{contact_us.address}}  </p>
					<p><img src="@/assets/contactus/contactus_icon_2.png"/>{{contact_us.hotline_num}}</p>
				</div>
				<div class="us-info-li">
					<img src="@/assets/contactus/contactus_icon_3.png"/>
					<h6>{{lang.contact_us_office}}</h6>
					<p>{{contact_us.office_num}}  </p>
					<p>{{contact_us.office_email}}</p>
				</div>
				<div class="us-info-li">
					<img src="@/assets/contactus/contactus_icon_4.png"/>
					<h6>{{lang.contact_us_sale}}</h6>
					<p>{{contact_us.sale_num}}  </p>
					<p>{{contact_us.sale_email}}</p>
				</div>
				<div class="us-info-li">
					<img src="@/assets/contactus/contactus_icon_5.png"/>
					<h6>{{lang.contact_us_purchase}}</h6>
					<p>{{contact_us.purchase_num}}</p>
					<p>{{contact_us.purchase_email}}</p>
				</div>
			</div>
			<div class="us-info">
				<div class="us-info-li-three">
					<div>
						<img src="@/assets/contactus/contactus_icon_6.png"/>
						<h6>{{lang.contact_us_marketing}}</h6>
					</div>
					<div>
						<p>{{contact_us.marketing_num}}</p>
						<p>{{contact_us.marketing_email}}</p>
					</div>
				</div>
				<div class="us-info-li-three">
					<div>
						<img src="@/assets/contactus/contactus_icon_7.png"/>
						<h6>{{lang.contact_us_in_out}}</h6>
					</div>
					<div>
						<p>{{contact_us.in_out_num}}</p>
						<p>{{contact_us.in_out_email}}</p>
					</div>
				</div>
				<div class="us-info-li-three">
					<div>
						<img src="@/assets/contactus/contactus_icon_8.png"/>
						<h6>{{lang.contact_us_HR}}</h6>
					</div>
					<div>
						<p>{{contact_us.hr_num}}</p>
						<p>{{contact_us.hr_email}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getContactUs } from "@/api/index";
	import { getAdList } from '@/api/store.js'
	import {bannerType} from "@/utils/type.js"
	import {mapState} from 'vuex'
	export default {
		name: "contactus",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				contact_us:'',
				bannerId:bannerType.contactUs,
				img_list:[{img:''}],
			}
		},
		created() {
			this.getContactUs()
			this.getAdListFun()
		},
		methods:{
			getAdListFun(){
				// 获取广告位图片
				let data = {
					cate_id:this.bannerId
				}
				getAdList(data).then(res=>{
					if(res.data.img_list){
						this.img_list = res.data.img_list
					}
				})
			},
			getContactUs(){
				getContactUs().then(res=>{
					this.contact_us=res.data.contact_us
					//this.info=res.data.article_info.info
				})
			}
		}
	
	
	
	
	}
</script>

<style lang="less">
	.main{
		background-color: #f2f2f2;
		
	}
	.contactus{
		min-height: 30rem;
		h5{
			text-align: center;
			margin: 3.6875rem 0 3.0625rem;
		}
		.us-info{
			
			.us-info-li{
				text-align: center;
				float: left;
				background-color: #fff;
				border-radius: 0.5rem;
				padding: 1rem 2.3125rem;
				width: 17.625rem;
				margin-right: 1.4375rem;
				height: 9.8125rem;
				margin-bottom: 1.4375rem;
				img{
					width: 3.125rem;
					height: 3.125rem;
				}
				h6{
					font-size: 0.875rem;
					font-weight: bold;
					margin: 0.5rem 0;
				}
				p{
					font-size: 0.875rem;
					margin-bottom: 0;
					img{
						width: 1.875rem;
						height: 1.875rem;
					}
				}
			}
			.us-info-li:last-child{
				margin-right: 0;
			}
			.us-other{
				text-align: left;
				p{
					margin-top: 1.5rem;
					text-align: left;
				}
			}
			.us-info-li-three{
				float: left;
				background-color: #fff;
				border-radius: 0.5rem;
				padding: 1rem ;
				margin-right: 1.4375rem;
				width: 24rem;
				height: 10.1875rem;
				display: flex;
				align-items: center;
				/* 垂直居中 */
				/* 容器高度 */
				justify-content: center;
				div{
					margin-right: 1.5rem;
					    text-align: center;
				}
				img{
					width: 3.125rem;
					height: 3.125rem;
				}
				p{
					text-align: center;
					margin-top: 0.5rem;
					margin-bottom: 0;
				}
			}
			.us-info-li-three:last-child{
				margin-right: 0;
			}
		}
	}
	@media (max-width: 1220px) {
		.contactus .us-info .us-info-li {
		    width: 23.5%;
		    margin-right: 2%;
		}
		.contactus .us-info .us-info-li-three {
		    margin-right: 2%;
		    width: 32%;
		}
	}
	@media (max-width: 1024px){
		.contactus {
		    min-height: 53rem;
		}
		.contactus .us-info .us-info-li {
		    width: 49%;
		}
		.contactus .us-info .us-info-li:nth-child(2n),.contactus .us-info .us-info-li-three:nth-child(2n) {
		    margin-right: 0;
		}
		.contactus .us-info .us-other {
		    text-align: center;
		}    
		.contactus .us-info .us-info-li-three {
		    width: 49%;
			margin-bottom: 1.4375rem;
		}    
	}
	@media (max-width: 768px){
		
		.contactus .us-info .us-info-li,.contactus .us-info .us-info-li-three{
			width: 100%;
			margin-right: 0;
			float: none;
		}
		.contactus .us-info .us-info-li-three div{
			font-size: 0.875rem;
		}
	}
</style>